import React from "react"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"
import Typewriter from "typewriter-effect"
import { HeroConfig } from "../../config/config-data"
import "../../styles/hero.css"


const Hero = ({ ModalState, GradientAnimation }) => {

  return(
    <>
      <div id="hero_1662792922934" className="Hero">
        <div id="hero_1662792924886" className="Hero_Overlay">
          {!GradientAnimation && 
          <Div100vh>
            <div id="hero_1662792926278" className="Hero__Card">
              <div id="hero_1662792927414" style={{justifyContent:"flex-start", alignItems:"center"}} className="Flex_Container">
                <div id="hero_1662792928197" style={{textAlign:"left", paddingBottom:"10px"}}>
                  {HeroConfig.hero_heading_typewriter_text &&
                  <h1 id="hero_1662792929093" className="Hero_Heading_Text Heading_Gradient">
                    {HeroConfig.hero_heading_typewriter_before_text}
                    <Typewriter options={{strings: HeroConfig.hero_heading_typewriter_text, autoStart: true, loop: true, cursorClassName: "Custom_Typewriter_Cursor", cursor: "&#65075;"}}/>
                    {HeroConfig.hero_heading_typewriter_after_text}
                  </h1>
                  }
                  {HeroConfig.hero_heading_text &&
                  <h1 id="hero_1662792932229" className="Hero_Heading_Text Heading_Gradient">{HeroConfig.hero_heading_text}</h1>
                  }
                  {HeroConfig.hero_text &&
                  <h2 id="hero_1662792933317" className="Hero_Text">{HeroConfig.hero_text}</h2>
                  }
                  {
                  HeroConfig.button_label
                  ?
                  HeroConfig.button_url_link
                  ?
                  <a id="hero_1662792936581" href={HeroConfig.button_url_link} aria-label="Map Location" target={button_url_new_tab && "_blank"} rel="noopener noreferrer"><button id="hero_1662792953989" className="Floating">{HeroConfig.button_label}</button></a>
                  :
                  HeroConfig.button_page_link 
                  ?
                  <Link id="hero_1662792939486" exact="true" to={HeroConfig.button_page_link}><button id="hero_1662792941421" className="Floating">{HeroConfig.button_label}</button></Link>
                  :
                  <button id="hero_1662792943445" className="Floating" onClick={ModalState}>{HeroConfig.button_label}</button>
                  :
                  ''
                  }
                  {
                  HeroConfig.link_label
                  ?
                  HeroConfig.link_url_link
                  ?
                  <a id="hero_1662792945277" className="Hero_Link" href={HeroConfig._link} aria-label="Map Location" target={link_url_new_tab && "_blank"} rel="noopener noreferrer">{HeroConfig.link_label}</a>
                  :
                  HeroConfig.link_page_link 
                  ?
                  <Link id="hero_1662792946173" className="Hero_Link" exact="true" to={HeroConfig.link_page_link}>{HeroConfig.link_label}</Link>
                  :
                  <a id="hero_1662792947548" className="Hero_Link" onClick={ModalState}>{HeroConfig.link_label}</a>
                  :
                  ''
                  }
                </div>
              </div>
            </div>
            <div id="hero_1662792949533" className="Arrow_Down">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 278.6C175.6 284.9 183.8 288 192 288s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0L192 210.8L54.63 73.38c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L169.4 278.6zM329.4 265.4L192 402.8L54.63 265.4c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l160 160C175.6 476.9 183.8 480 192 480s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25S341.9 252.9 329.4 265.4z"/></svg>
            </div>
          </Div100vh>
          }
        </div>
      </div>
    </>
  );
};


export default Hero
  